* {
  box-sizing: border-box;
  font-family: 'Roboto-light', sans-serif;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

textarea {
    border-radius: 5px;
    border-color: #95BEE6;
    color: #95BEE6;
    font-size: 16px;
    padding: 15px;
    width: 100%;
}

.cdk-overlay-container {
  background-color: rgba(0,0,0,0.7);
}